<template>
  <div class="module-common pc-common">
    <div>
      <div class="category-wrap">
        <div class="Tab--tabWrapAll--IlpEnd4">
          <div class="Tab--tabWrap--usAaiUA">
            <div class="Tab--baseStyle--izOIUCL Tab--actStyle--bq3TL6m">
              所有商品
              <div class="Tab--underline--V58GBfS"></div>
            </div>
          </div>
          <div class="Tab--line--uvEgww5"></div>
        </div>
        <el-tabs
          v-model="activeName"
          class="goods-tabs"
          @tab-click="handleClick"
        >
          <div class="product_line"></div>
          <el-tab-pane
            v-for="(value, index) in catesList"
            :label="value.categoryName"
            :key="index"
            :name="value.categoryName"
            :value="value.id"
          >
            <!-- 二级目录 -->
            <div style="background-image: linear-gradient(0deg, #fff, #f6f6f6)">
              <div style="padding: 10px 5px 0px 5px">
                <span
                  v-for="(item, index) in value.children"
                  :label="item.categoryName"
                  :key="index"
                  :span="2"
                  class="index_2_subName"
                  :class="{ subNameClass: index == selectedSubIndex }"
                >
                  <span
                    style="cursor: pointer; margin-top: 10px; white-space: pre"
                    @click="queryCateSub(item, index)"
                  >
                    {{ item.categoryName }}
                  </span>
                </span>
              </div>
            </div>
            <!-- <el-divider></el-divider> -->

            <div style="height: 600px">
              <van-list
                v-model="loadingState"
                :finished="finished"
                finished-text="没有更多了"
                @load="loadMore"
                style="background-color: #ffffff; padding: 0px 0px 20px 0px"
              >
                <el-row :gutter="10">
                  <el-col :span="5" v-for="(v, k, i) in goodsList" :key="i">
                    <div class="px-goods-card" @click="toProductDetails(v)">
                      <div>
                        <el-image
                          :src="v.picUrl"
                          style="width: 100%; height: 226px"
                        ></el-image>
                      </div>
                      <div class="goods2_content">
                        <div style="height: 45px; overflow: hidden">
                          {{ v.productName }}
                        </div>
                        <div style="margin: 10px 0px">
                          <!-- 商品类型 -->
                          <!-- <el-tag
                            effect="dark"
                            size="mini"
                            :color="v.productTypeColor"
                          >
                            {{ v.productTypeText }}
                          </el-tag> -->

                          <!-- 库存 -->
                          <!-- <template>
                            <el-tag size="mini" style="margin-left: 10px">
                              库存:10
                            </el-tag>
                          </template> -->

                          <!-- 销量 -->
                          <!-- <template>
                            <el-tag size="mini" style="margin-left: 10px">
                              销量:10
                            </el-tag>
                          </template> -->
                        </div>
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 15px;
                          "
                        >
                          <div class="index2_price">
                            <span v-html="$decimalPrice(v.price)"></span>
                            <!-- <el-tag size="small" style="float: right">
                            商品编号：{{ v.id }}
                          </el-tag> -->
                          </div>

                          <span
                            style="
                              border: 1px solid #e1c1c7;
                              background-color: #fef4f3;
                              display: inline-block;
                              height: 26px;
                              padding: 0 10px;
                              line-height: 24px;
                              font-size: 12px;
                              color: #d57081;
                              border-radius: 20px;
                            "
                          >
                            立即购买
                          </span>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </van-list>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      selectSubIndex: undefined,
      activeName: "",
      selectedIndex: 0, // 默认选中第一个li元素
      selectedSubIndex: undefined,
      catesList: [],
      goodsList: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        categoryId: undefined,
        categoryPid: undefined,
      },
      finished: false,
      loadingState: false,
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      productList: (state) => state.index.productList,
    }),
  },

  created() {
    //查收商品分类
    this.getCateList();
    //查询商品列表
    this.getProductList();
  },
  methods: {
    numToStr(num) {
      num = num.toString();
      return num;
    },

    /**
     * 查询商品分类
     */
    getCateList() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
        this.activeName = this.catesList[0].categoryName; // 获取第一个选项卡的名称
      });
    },

    /**
     * 加载更多
     */
    loadMore() {
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;
      this.getProductList();
    },

    //查询商品列表
    getProductList() {
      this.$store
        .dispatch("index/queryProductList", this.queryParam)
        .then(() => {
          this.goodsList = this.goodsList.concat(this.productList.productList);
          this.total = this.productList.total;

          if (this.productList.productList.length == 0) {
            this.finished = true;
          }
          this.loadingState = false;
        });
    },

    handleClick(tab, event) {
      console.log(tab, event);

      //初始化参数
      this.queryParam.categoryPid = tab.$attrs.value;
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryId = null;

      this.loadingState = false;
      this.finished = false;
      this.goodsList = [];

      this.getProductList();
    },

    /**
     * 根据 */
    queryCateSub(value, index) {
      this.selectedSubIndex = index;
      //初始化参数
      this.queryParam.categoryId = value.id;
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryPid = null;

      this.loadingState = false;
      this.finished = false;
      this.goodsList = [];

      this.getProductList();
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/pgoodsDetail/${v.id}`);
    },
  },
};
</script>

<style>
.category-wrap {
  /* transition: 0.6s; */
  background-color: #fff;
  /* border: 1px solid #f2f2f2; */
  line-height: 32px;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.category-wrap .label {
  transition: 0.2s;
  cursor: pointer;
  font-size: 16px;
}

.category-wrap .label:hover {
  color: #14d3b7;
}

.px-goods-card {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  margin-top: 10px;
}

.px-goods-card .goods2_content {
  line-height: 24px;
}

.px-goods-card .goods2_content .index2_price {
  height: 24px;
  font-size: 18px;
  align-items: baseline;
  color: #ff4f42;
  /* font-size: 14px;
  display: flex;
  color: #f44336;
  align-items: center;
  margin-bottom: 20px; */
}

.px-goods-card:hover {
  border: 1px solid #14d3b7;
}

/deep/.el-dialog {
  border-radius: 5px;
}

/* 美化tabs */
.goods-tabs {
  padding: 0px 10px;
}
.goods-tabs .el-tabs__header {
  background-color: #ffffff;
  border-radius: 20px;
  margin: 0px;
  /* margin-bottom: 20px; */
}

.goods-tabs .el-tabs__item {
  color: #606266;
  border: none;
  height: 50px;
  line-height: 50px;
  /* margin-right: 10px; */
  font-size: 16px;
  padding: 0 15px;
}

.goods-tabs .el-tabs__item.is-active {
  background-color: rgb(246, 246, 246);
  color: #ff5f00;
  border-radius: 12px 12px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  /* padding: 12px 16px; */
}

.goods-tabs .el-tabs__pane {
  padding: 10px;
}

.goods-tabs .el-tabs__nav {
  padding: 0px;
}

.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 15px !important;
}

.el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 15px !important;
}

.goods-tabs .el-tabs__active-bar {
  background-color: transparent !important;
}

.goods-tabs .el-col-5 {
  width: 20%;
}

.f-hd {
  /* color: #8d8e99; */
  text-align: center;
  height: 100%;
  white-space: nowrap;
  font-size: 16px;
  /* font-weight: 700; */
}

/* 新的 */

.Tab--tabWrapAll--IlpEnd4 {
  margin-bottom: 12px;
  margin-top: 16px;
  width: auto;
}

.Tab--tabWrap--usAaiUA {
  background: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.Tab--tabNav--w5p1VWB {
  border-bottom: 1px solid #fff !important;
  height: 74px;
}

.Tab--baseStyle--izOIUCL {
  color: #000;
  cursor: pointer;
  font-family: AlibabaSans102v1TaoBao-Bold, Alibaba-Sans102, PingFangSC-Regular;
  font-size: 18px;
  letter-spacing: 0;
  margin: 0 7px 0 18px;
}

.Tab--actStyle--bq3TL6m {
  font-weight: 700;
}

.Tab--underline--V58GBfS {
  background-color: #f50;
  border-radius: 10px;
  height: 3px;
  margin: 17px auto 0;
  width: 80%;
}

.Tab--line--uvEgww5 {
  border-top: 1px solid hsla(0, 0%, 59%, 0.1);
}

.P4P--iframeBox--idJOjlK {
  background-color: #fff;
  width: 100%;
}

.P4P--iframeBodyWrap--r9wPZel {
  margin: 0 auto;
  width: 885px;
}

.P4P--iframeBody--hZ29in3 {
  height: 150px;
  margin-bottom: 0.5px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.P4P--iframeContent--r55cnrH {
  left: -92px;
  position: absolute;
  top: -15px;
  -webkit-transform: scale(0.827);
  -ms-transform: scale(0.827);
  transform: scale(0.827);
}

.index_2_subName {
  color: rgb(255 255 255);
  /* text-align: center; */
  border-radius: 5px;
  background-color: #aaa;
  margin-right: 10px;
  /* margin-top: 116px; */
  padding: 3px 10px;
  font-size: 13px;
}
.subNameClass {
  color: #ffffff;
  text-align: center;
  border-radius: 18px;
  background-color: #1a87ff;
  margin-right: 10px;
  margin-top: 16px;
  padding: 3px 10px;
}
</style>
